<!-- 根据放映点查所属放映公司的放映员 -->
<template>
  <div>
    <van-popup
      v-model="showPopup"
      round
      position="bottom"
      :close-on-click-overlay="false"
    >
     <van-picker title="选择放映员"  show-toolbar :columns="teamList" @confirm="onConfirm" @cancel="close"  />
    </van-popup>
  </div>
</template>

<script>
import {api} from '@/api'
import { Toast } from 'vant'
export default {
  name: 'index',
  data() {
    return {
      teamList: [],
      showPopup: false,
    }
  },
  mounted() {
  },
  methods: {
    close() {
      this.$emit('close')
    },
    show(pointId){
      Toast.loading({
        duration: 0,
        forbidClick: true,
      });
      this.showPopup = true
      api.filmmaker.getShowPointsAll({
        showPointId: pointId,
        pageNum: 1,
        pageSize: 1000,
      }).then(res => {
        const {success,result} =res
        if(success){
          const {records} = result
          const teamList = []
          records.forEach(item => {
            teamList.push({text:item.name, value:item})
          })
          this.teamList = teamList
        }
      })
    },
    onConfirm(option, index) {
      // console.log(option, index)
      // console.log({ selectOption })
      this.showPopup = false
      this.$emit('onConfirm', option.value)
    },
  },
}
</script>

<style scoped lang="less">
::v-deep .van-picker-column {
  overflow: hidden !important;
}
</style>
